import CartProductRecommendationItem from '@/components/cart-product-recommendation-item';
import '@/utils/bugsnag';
import { updatePage } from '@/utils/update-page';
import AccordionGroup from '~/components/accordion-group';
import Blaze from '~/components/blaze/index';
import Carousel from '~/components/carousel';
import CartDrawer from '~/components/cart-drawer';
import Cart from '~/components/cart/index';
import FeaturedReviews from '~/components/featured-reviews';
import FullWidthVideo from '~/components/full-width-video';
import Header from '~/components/header';
import LinkCarousel from '~/components/link-carousel';
import ListCarousel from '~/components/list-carousel';
import NewsletterSignup from '~/components/newsletter-signup';
import ProductCarousel from '~/components/product-carousel';
import ProductCategoryCarousel from '~/components/product-category-carousel';
import ProductFeature from '~/components/product-feature';
import Quote from '~/components/quote';
import UI from '~/components/ui-state/index';
import VideoPlayer from '~/components/video-player/index';
// import ImageZoom from '~/components/image-zoom/index';
import ProductReviews from '~/components/product-reviews/index';
import UpdatePage from '@/components/update-page/index';
import CollectionMenu from '@/components/collection-menu/index';

import { UserInteractionEvents } from '~/types';

const init = async () => {
  const { default: Alpine } = await import('alpinejs');
  const { default: validate } = await import(
    '@colinaut/alpinejs-plugin-simple-validate'
  );

  const { default: AlpineIntersect } = await import('@alpinejs/intersect');
  const { default: AlpineCollapse } = await import('@alpinejs/collapse');

  const { default: ProductView } = await import('@/components/product-view');
  const { default: LSGProductView } = await import('@/components/lsg-product-view');
  const { default: NotifyMeModal } = await import(
    '@/components/notify-me-modal'
  );
  const { default: StickyAddToCart } = await import(
    '@/components/sticky-add-to-cart'
  );
  const { default: Tooltip } = await import('@/components/tooltip');
  const { default: ProductQuality } = await import(
    '@/components/product-quality'
  );
  const { default: MobileMenu } = await import('@/components/mobile-menu');
  const { default: ProductQuickview } = await import(
    '@/components/product-quickview'
  );

  const { default: Search } = await import('@/components/search');

  Alpine.plugin(AlpineIntersect);
  Alpine.plugin(AlpineCollapse);

  Alpine.plugin(Cart);

  Alpine.plugin(validate);
  Alpine.plugin(Blaze);
  Alpine.plugin(VideoPlayer);

  Alpine.plugin(UI);
  Alpine.plugin(NewsletterSignup);
  Alpine.plugin(AccordionGroup);
  Alpine.plugin(Carousel);
  Alpine.plugin(ListCarousel);
  Alpine.plugin(Header);
  Alpine.plugin(LinkCarousel);
  Alpine.plugin(FeaturedReviews);
  Alpine.plugin(ProductFeature);
  Alpine.plugin(Quote);
  Alpine.plugin(ProductCarousel);
  Alpine.plugin(ProductCategoryCarousel);
  Alpine.plugin(FullWidthVideo);
  Alpine.plugin(ProductView);
  Alpine.plugin(LSGProductView);
  Alpine.plugin(NotifyMeModal);
  Alpine.plugin(StickyAddToCart);
  Alpine.plugin(Tooltip);
  Alpine.plugin(ProductQuality);
  Alpine.plugin(MobileMenu);
  Alpine.plugin(ProductQuickview);
  Alpine.plugin(CartProductRecommendationItem);
  Alpine.plugin(Search);
  // Alpine.plugin(ImageZoom);
  Alpine.plugin(ProductReviews);
  Alpine.plugin(UpdatePage);
  Alpine.plugin(CollectionMenu);

  Alpine.plugin(CartDrawer);

  window.Alpine = Alpine;
  window.updatePage = updatePage;
  Alpine.start();
};

const timeout = setTimeout(() => {
  _eventHandler();
}, 800);

const _eventHandler = () => {
  clearTimeout(timeout);

  Object.values(UserInteractionEvents).forEach(event =>
    document.removeEventListener(event, _eventHandler, {
      capture: true,
    })
  );
};

init();

const _addEventListeners = (event: UserInteractionEvents) => {
  document.addEventListener(event, _eventHandler, {
    capture: true,
  });
};

Object.values(UserInteractionEvents).forEach(event => {
  _addEventListeners(event);
});
