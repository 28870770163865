import { Alpine as AlpineType } from 'alpinejs';
import BlazeSlider, { BlazeConfig } from 'blaze-slider';

export default (Alpine: AlpineType) => {
  Alpine.data('productReviews', (product_id: string) => ({
    url: "https://fast.a.klaviyo.com/reviews/api/client_reviews/"+product_id+"/?product_id="+product_id+"&company_id=a6ibCM&limit=50&offset=0&filter=&type=reviews&media=false&rating=5",
    reviews: [] as string[],

    init(): void {
      this.getProductReviews();
    },

    async getProductReviews(): Promise<void> {
      try {
        const response = await fetch(this.url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        })
        const responseJson = await response.json()
        const all_reviews = responseJson.reviews
        all_reviews.some((review: any) => {
          if(review.title != null && review.content.length > 100) {
            this.reviews.push(review)
          }
          if(this.reviews.length >= 5) {
            return true
          }
        })
      } catch (error) {
        console.error(error);
      } finally {
        if(this.reviews.length > 0) {
          setTimeout(() => {
            const slides = this.$el.querySelector('.blaze-container-slides')?.querySelectorAll('[slide]')
            slides?.forEach((slide: any) => {
              this.$el.querySelector('.blaze-track')?.appendChild(slide);
            });

            new BlazeSlider(this.$el, {
              all: {
                slidesToShow: 1,
              }
            })
          }, 500)
        }
      }
    }
  }));
}
