import { Alpine as AlpineType } from 'alpinejs';
import BlazeSlider, { BlazeConfig } from 'blaze-slider';

export default (Alpine: AlpineType) => {
  Alpine.data('blaze', (props, isCentered) => ({
    showNavSlider: false,
    centerSlide: false,
    loaderHidden: false,
    slider: null as null | BlazeSlider,

    async init() {
      const { default: BlazeSlider } = await import('blaze-slider');
      this.slider = new BlazeSlider(this.$el, props as BlazeConfig);

      this.hideArrows(this.slider);
      if (isCentered) {
        this.centeringSlides(this.slider);
      }

      if (this.slider !== null) {
        this.hideLoader();
      }

      this.$el.classList.add('blaze-slider-initialized');
    },

    centeringSlides(blazeSlider: BlazeSlider) {
      const slidesLength = blazeSlider.slides.length;
      const slidesToShow = blazeSlider.track.slider.config.slidesToShow;

      if (slidesLength <= slidesToShow) {
        this.centerSlide = true;
      }
    },

    hideLoader() {
      this.$nextTick(() => {
        this.loaderHidden = true;
      });
    },

    hideArrows(blazeSlider: BlazeSlider) {
      const slidesLength = blazeSlider.slides.length;
      const slidesToShow = blazeSlider.track.slider.config.slidesToShow;

      this.showNavSlider = slidesLength > slidesToShow;
    },
  }));
};
